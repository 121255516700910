import { useEffect, useMemo, useRef, useState } from "react";
import { createPortal } from "react-dom";

const { MindARThree } = window.MINDAR.IMAGE;

export const MindARComponent = ({
  book,
  onImageFound,
  setVisibleTrackable,
  scanning,
}) => {
  const animationFrameID = useRef(null);
  const [mindaARloaded, setMindARloaded] = useState(false);
  const mindarThreeInstance = useMemo(() => {
    return new MindARThree({
      container: document.querySelector("#ar-viewport"),
      warmupTolerance: 5,
      missTolerance: 5,
      uiScanning: "no",
      uiLoading: "no",
      imageTargetSrc:
        book === "sedona"
          ? "https://magic-portal-v1.s3.us-east-2.amazonaws.com/sedona/targets/targets.mind"
          : "https://magic-portal-v1.s3.us-east-2.amazonaws.com/sherman/targets/targetsV3.mind",
    });
  }, [book]);

  useEffect(() => {
    if (mindarThreeInstance && scanning) {
      console.log("loading");
      const loadMindAR = async () => {
        // Start the MindAR instance
        await mindarThreeInstance.start();
        setMindARloaded(true);
      };
      loadMindAR();
    }
  }, [mindarThreeInstance, scanning]);

  useEffect(() => {
    if (scanning && mindaARloaded) {
      const checkingTargets = () => {
        if (
          mindarThreeInstance.controller.trackingStates.find(
            (el) => el.isTracking
          )
        ) {
          setVisibleTrackable(
            mindarThreeInstance.controller.trackingStates.findIndex(
              (el) => el.isTracking
            )
          );
          onImageFound();
        }
        animationFrameID.current = requestAnimationFrame(checkingTargets);
      };
      checkingTargets();
    } else if (!scanning && mindaARloaded) {
      if (animationFrameID.current) {
        console.log("cancelAnimationFrame");
        cancelAnimationFrame(animationFrameID.current);
      }
      if (mindarThreeInstance) {
        console.log("stopProcessVideo");
        setMindARloaded(false);
        mindarThreeInstance.stop();
      }
    }
  }, [
    scanning,
    mindaARloaded,
    mindarThreeInstance,
    setVisibleTrackable,
    onImageFound,
  ]);

  //   useEffect(() => {
  //     console.log("MINDAR useEffect");
  //     const loadMindAR = async () => {
  //       // Start the MindAR instance
  //       await mindarThreeInstance.start();
  //     };
  //     if (onceRef.current === 0) loadMindAR();
  //     onceRef.current = 1;

  //     // Cleanup function to remove event listeners
  //     return () => {
  //       if (animationFrameID.current) {
  //         console.log("cancelAnimationFrame");
  //         cancelAnimationFrame(animationFrameID.current);
  //       }
  //       if (mindarThreeInstance.controller) {
  //         console.log("stopProcessVideo");

  //         mindarThreeInstance.controller.stopProcessVideo();
  //       }
  //     };
  //   }, []);

  return (
    <>
      {!mindaARloaded &&
        scanning &&
        createPortal(
          <>
            <div className="targetImagesLoader">
              <div>
                Before scanning the page
                <br />
                Wait for the target
                <br />
                images to load...
              </div>
            </div>
          </>,
          document.body
        )}
    </>
  );
};
