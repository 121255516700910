import { DeviceOrientationControls, OrbitControls } from "@react-three/drei";
import { useGesture } from "@use-gesture/react";
import { useRef } from "react";

export const Controls = ({
  motionPerm,
  cameraGroupRef,
}: {
  motionPerm: boolean;
  cameraGroupRef?: any;
}) => {
  return (
    <>
      {motionPerm && (
        <CombinedControls
          cameraGroupRef={cameraGroupRef}
          motionPerm={motionPerm}
        />
      )}
      <OrbitControls
        enableZoom={false}
        reverseOrbit={true}
        enabled={motionPerm ? false : true}
      />
    </>
  );
};

const CombinedControls = ({ cameraGroupRef }: any) => {
  const cameraGroupRotation = useRef(0);

  useGesture(
    {
      onDragStart: ({ pinching }) => {
        if (!pinching) {
          cameraGroupRotation.current = cameraGroupRef.current.rotation.y;
          console.log("Drag Start");
        }
      },
      onDrag: ({ movement: [s], pinching }) => {
        if (!pinching) {
          cameraGroupRef.current.rotation.y =
            cameraGroupRotation.current + (s / 720) * Math.PI;
          console.log("Dragging", s);
        }
      },
      onDragEnd: ({ pinching }) => {
        if (!pinching) {
          cameraGroupRotation.current = cameraGroupRef.current.rotation.y;
          console.log("Drag End");
        }
      },
    },
    {
      target: document.body,
      eventOptions: { passive: false },
      preventScroll: true,
      preventDefault: true,
    }
  );
  return (
    <>
      <DeviceOrientationControls />
    </>
  );
};
