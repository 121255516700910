import {
  createContext,
  lazy,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { Canvas, useThree } from "@react-three/fiber";
import { requestMotionPermission } from "./motionPermission";
import { Controls } from "./Controls";
import { LoadingScreen } from "./components/LoadingScreen";
import { useGesture } from "@use-gesture/react";
import { PerspectiveCamera } from "@react-three/drei";
import { MindARComponent } from "./Mindaar";

export interface Trackable {
  width: number;
  height: number;
  id?: number;
  aspect?: number;
  dpi: number;
  url: string;
  wasVisible?: boolean;
  isVisible?: (show: boolean, restart?: boolean) => void;
}

export const IntercationContext = createContext(false);
export const SensorsContext = createContext(false);
export const SceneLoadedContext = createContext<any>(null);
export const ScanSessionContext = createContext<any>(null);

const Sedona = lazy(() => import("./portals/Sedona"));
const Sherman = lazy(() => import("./portals/Sherman"));
const ShermanReadAlong = lazy(() => import("./portals/ShermanReadAlong"));
const ShermanReadAlongV2 = lazy(() => import("./portals/ShermanReadAlongV2"));
const ShermanPreview = lazy(() => import("./portals/ShermanPreview"));
const SedonaPreview = lazy(() => import("./portals/SedonaPreview"));

const queryString = window.location.search;
console.log(queryString);
const urlParams = new URLSearchParams(queryString);
const product = urlParams.get("product") || "prev-se";
console.log(product);
const page = urlParams.get("page") || undefined;

function App() {
  const [scanninHelper, setScanningHelper] = useState(false);
  const scanningHelperRef = useRef(false);
  const [targetImages, setTargetImages] = useState<any>(null);
  const [scanSession, setScanSession] = useState<any>(false);
  const [visibleTrackable, setVisibleTrackable] = useState<string | undefined>(
    page
  );
  const [checkingForSensors, setCheckingForSensors] = useState(true);
  const [lottieAnimationLoaded, setLottieAnimationLoaded] = useState(false);
  const [hadInteraction, setHadInteraction] = useState(false);
  const [sceneLoaded, setSceneLoaded] = useState<any>(true);
  const cameraGroupRef = useRef<any>(null);

  const [motionPerm, setMotionPerm] = useState<any>({
    haveSensors: false,
    needPermissions: true,
  });

  const startScanning = useCallback(() => {
    setScanSession(true);
  }, []);

  const requestMotionPerm = useCallback(async (e?: any) => {
    const perm = await requestMotionPermission(e);
    if (!perm.needPermissions) {
      setSceneLoaded(false);
    }
    setMotionPerm(perm);
    setCheckingForSensors(false);
  }, []);

  const loadTargetImages = useCallback(
    (folder: string, lastPageNum: number) => {
      const array = new Array(lastPageNum).fill(undefined);
      let loadedImages = 0;

      const checkAllImagesLoaded = () => {
        loadedImages++;
        if (loadedImages === lastPageNum) {
          console.log(array);
          setTargetImages(array);
        }
      };

      for (let i = 1; i <= lastPageNum; i++) {
        const img = new Image();
        img.crossOrigin = "anonymous";
        img.src = `${folder}/Page${i}.jpg`;

        img.onload = () => {
          array[i - 1] = img;
          checkAllImagesLoaded();
        };

        img.onerror = () => {
          array[i - 1] = undefined;
          checkAllImagesLoaded();
        };
      }
    },
    []
  );

  useEffect(() => {
    requestMotionPerm();
  }, [requestMotionPerm]);

  useEffect(() => {
    if (!motionPerm.needPermissions) {
      setSceneLoaded(false);
    }
  }, [motionPerm]);

  useEffect(() => {
    if (visibleTrackable) {
      setSceneLoaded(false);
    }
  }, [visibleTrackable]);

  useEffect(() => {
    if (scanSession) {
      document.getElementById("ar-viewport-wrapper")!.classList.add("show");
    } else {
      document.getElementById("ar-viewport-wrapper")!.classList.remove("show");
    }
  }, [scanSession]);

  useEffect(() => {
    const interaction = () => {
      setHadInteraction(true);
    };
    document.addEventListener("click", interaction);
    document.addEventListener(
      "touchmove",
      (e) => {
        // prevent swipe to navigate gesture
        e.preventDefault();
      },
      { passive: false }
    );

    return () => {
      document.removeEventListener("click", interaction);
    };
  }, []);

  return (
    <>
      <div className="App">
        <>
          {!motionPerm.needPermissions && lottieAnimationLoaded && (
            <ScanSessionContext.Provider
              value={{ scanSession, setScanSession }}
            >
              <SensorsContext.Provider value={motionPerm.haveSensors}>
                <SceneLoadedContext.Provider value={setSceneLoaded}>
                  <IntercationContext.Provider value={hadInteraction}>
                    <Canvas className="canvasWrapper">
                      <group ref={cameraGroupRef}>
                        <PerspectiveCamera
                          makeDefault
                          fov={75}
                          aspect={window.innerWidth / window.innerHeight}
                          near={0.1}
                          far={1000}
                          position={[0, 0, 0.001]}
                        />
                      </group>
                      <Controls
                        motionPerm={motionPerm.haveSensors}
                        cameraGroupRef={cameraGroupRef}
                      />

                      <PinchZoomCamera />

                      {!scanSession && (
                        <>
                          {product === "sedona" && (
                            <Sedona
                              visibleTrackable={visibleTrackable!}
                              loadTargetImages={loadTargetImages}
                            />
                          )}
                          {product === "sherman" && (
                            <Sherman
                              visibleTrackable={visibleTrackable!}
                              loadTargetImages={loadTargetImages}
                            />
                          )}
                          {product === "sherman-read-along-v1" && (
                            <ShermanReadAlong
                              visibleTrackable={visibleTrackable!}
                              loadTargetImages={loadTargetImages}
                            />
                          )}
                          {product === "sherman-read-along-v2" && (
                            <ShermanReadAlongV2
                              visibleTrackable={visibleTrackable!}
                              loadTargetImages={loadTargetImages}
                            />
                          )}
                          {product === "prev-sh" && <ShermanPreview />}
                          {product === "prev-se" && <SedonaPreview />}
                        </>
                      )}
                    </Canvas>
                  </IntercationContext.Provider>
                </SceneLoadedContext.Provider>
              </SensorsContext.Provider>
            </ScanSessionContext.Provider>
          )}

          {!scanSession &&
            !motionPerm.needPermissions &&
            product !== "sherman-read-along-v1" &&
            product !== "sherman-read-along-v2" &&
            product !== "prev-sh" &&
            product !== "prev-se" && (
              <button
                className="scanBtn"
                onClick={() => {
                  startScanning();
                }}
              >
                <img
                  src={`https://magic-portal-v1.s3.us-east-2.amazonaws.com/sedona/images/buttons/scanBtn.png`}
                  alt=""
                />
              </button>
            )}
          {product !== "prev-sh" && product !== "prev-se" && (
            <MindARComponent
              book={product}
              scanning={scanSession && !motionPerm.needPermissions}
              onImageFound={() => {
                setScanSession(false);
              }}
              setVisibleTrackable={setVisibleTrackable}
            />
          )}

          {scanSession && !motionPerm.needPermissions && !scanninHelper && (
            <>
              <div className="scanningUI">
                <button
                  onClick={() => {
                    scanningHelperRef.current = true;
                    setScanningHelper(true);
                  }}
                >
                  <img
                    src="https://magic-portal-v1.s3.us-east-2.amazonaws.com/sedona/images/buttons/helpBtn.png"
                    alt=""
                  />
                </button>
                <img
                  src="https://magic-portal-v1.s3.us-east-2.amazonaws.com/images/frame.png"
                  alt=""
                />
                <div>
                  Hold this frame still over character or
                  <br />
                  the center of the page.
                  <br />
                  Wait for Magic Portal to open!
                </div>
              </div>
            </>
          )}
          {scanninHelper && (
            <ScanningHelper
              setScanSession={setScanSession}
              setScanningHelper={setScanningHelper}
              scanningHelperRef={scanningHelperRef}
              trackerDatabase={targetImages.filter(
                (el: any) => el !== undefined
              )}
              setVisibleTrackable={setVisibleTrackable}
            />
          )}
        </>

        {motionPerm.needPermissions && (
          <>
            <div className="permissionsPopup">
              <div className="popup">
                <p>
                  For Virtual Reality to work,
                  <br />
                  Please allow access to sensors
                </p>
                <button
                  onClick={(e) => {
                    requestMotionPerm(e);
                    setHadInteraction(true);
                  }}
                >
                  <span>Okay</span>
                </button>
              </div>
            </div>
          </>
        )}
        {(checkingForSensors || !sceneLoaded) && (
          <LoadingScreen
            setLottieAnimationLoaded={setLottieAnimationLoaded}
            scanningHelperRef={scanningHelperRef}
          />
        )}
      </div>

      {/* TODO: only add video and start scanning when moving to next portal */}
    </>
  );
}

const PinchZoomCamera = ({ maxZoomIn = 2, maxZoomOut = 0.75 }) => {
  const { camera } = useThree();
  const zoomRef = useRef(camera.zoom);

  useGesture(
    {
      onPinch: ({ movement: [s] }) => {
        const newZoom = zoomRef.current * s; // Adjust zoom based on pinch scale
        camera.zoom = Math.min(Math.max(newZoom, maxZoomOut), maxZoomIn); // Limit the zoom value

        camera.updateProjectionMatrix();
      },
      onPinchEnd: () => {
        zoomRef.current = camera.zoom;
      },
    },
    {
      target: document.body,
      eventOptions: { passive: false },
      preventScroll: true,
      preventDefault: true,
    }
  );

  return null;
};

const ScanningHelper = ({
  setScanSession,
  setScanningHelper,
  scanningHelperRef,
  trackerDatabase,
  setVisibleTrackable,
}: any) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  return (
    <>
      <div className="scanningHelper">
        <div className="pageSelector">
          <span>PAGE</span>
          <div className="buttons">
            <button
              className="decrease"
              onClick={() => {
                currentIndex === 0
                  ? setCurrentIndex(trackerDatabase.length - 1)
                  : setCurrentIndex(currentIndex - 1);
              }}
            >
              <svg
                width={32}
                height={53}
                viewBox="0 0 32 53"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M26.2007 47.9373L4.99977 26.7364L26.2007 5.53549"
                  stroke="black"
                  strokeWidth={9.63727}
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </button>
            <span>
              {Number(
                trackerDatabase[currentIndex].currentSrc
                  .split("/")
                  .pop()
                  .replace(".jpg", "")
                  .substring(4)
              )}
            </span>
            <button
              className="increase"
              onClick={() => {
                currentIndex === trackerDatabase.length - 1
                  ? setCurrentIndex(0)
                  : setCurrentIndex(currentIndex + 1);
              }}
            >
              <svg
                width={32}
                height={53}
                viewBox="0 0 32 53"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M5.20068 5.53549L26.4016 26.7364L5.20068 47.9373"
                  stroke="black"
                  strokeWidth={9.63727}
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </button>
          </div>
        </div>
        <img src={trackerDatabase[currentIndex].currentSrc} alt="" />

        <div className="buttonWrapper">
          <button
            onClick={() => {
              setScanningHelper(false);
              scanningHelperRef.current = false;
            }}
          >
            <span>SCAN AGAIN</span>
          </button>
          <button
            onClick={() => {
              setScanningHelper(false);
              scanningHelperRef.current = false;
              setVisibleTrackable(currentIndex);
              setScanSession(false);
            }}
          >
            <span>TELEPORT ME</span>
          </button>
        </div>
      </div>
    </>
  );
};

export default App;
